
import api from './apiService';

// Fetch company information
export const getCompanyInformation = async () => {
    try {
        const response = await api.get('/settings');


        return response;

    } catch (error) {
        console.error("Error fetching company information:", error.response ? error.response.data : error.message);
        throw error;
    }
};

export const createCompanyInformation = async (companyData) => {
    try {
        const response = await api.post('/settings', companyData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response;
    } catch (error) {
        console.error("Error creating company information:", error.response ? error.response.data : error.message);
        throw error;
    }
};

// Update existing company information
export const updateCompanyInformation = async (id, companyData) => {
    try {
        const response = await api.put(`/settings/${id}`, companyData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response;

    } catch (error) {
        console.error("Error updating company information:", error.response ? error.response.data : error.message);
        throw error;
    }
};