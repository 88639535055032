import api from './apiService';

export const sendPayrollPDFMail = async (emp_id, year, month) => {
      const response = await api.post(`/sendpayrollpdf`, {
        emp_id,
        year,
        month
    });

    return response;
};
