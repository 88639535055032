import api from './apiService';

const authService = {
  login: async (formData) => {
    const response = await api.post(`/auth/login`, formData);
    return response; 
  },

  register: async (formData) => {
    const response = await api.post(`/auth/register`, formData);
    return response; 
  },

  fetchUsers: async () => {
    const response = await api.get(`/auth/users`);
    return response; 
  },

  updateUser: async (userId, formData) => {
    const response = await api.put(`/auth/users/${userId}`, formData);
    return response; 
  },

  deleteUser: async (userId) => {
    const response = await api.delete(`/auth/users/${userId}`);
    return response; 
  },

  changePassword: async (userId, newPassword) => {
    const response = await api.put(`/auth/users/${userId}/password`, { password: newPassword });
    return response; 
  },
};

export default authService;
