import api from './apiService';

export const fetchPayrolls = async (year, month) => {
    if (!year || !month) {
        throw new Error('Year and month are required to fetch payrolls');
    }
    const response = await api.get(`/payrolls/${year}/${month}`);
    return response;
};
export const getSalarySheetExcel = async (year, month) => {
    if (!year || !month) {
        throw new Error('Year and month are required to fetch payrolls');
    }
    const response = await api.get(`/payrolls/xls/${year}/${month}`, {
        responseType: 'blob',
    });
    return response;
};
// Save edited payroll
export const savePayroll = async (emp_code, year, month, updatedData) => {
    if (!emp_code || !year || !month || !updatedData) {
        throw new Error('Employee code, year, month, and updated data are required to save payroll');
    }
    const response = await api.put(`/payrolls/${emp_code}/${year}/${month}`, { payroll: updatedData });
    return response.data;
};
