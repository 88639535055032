import React, { useEffect, useState, useRef } from 'react'; // Import useRef
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Email = () => {
    const [smtpSettings, setSmtpSettings] = useState({});
    const formRef = useRef(); // Create a ref for the form

    
    const fetchSmtpSettings = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/smtp`);
            setSmtpSettings(response.data);
        } catch (error) {
            console.error('Error fetching SMTP settings:', error);
            const errorMessage = error.response?.data?.message || 'Error Loading Email Settings.';
            toast.error(errorMessage);
        }
    };

    useEffect(() => {
        fetchSmtpSettings();
    }, []);


    const handleSaveSettings = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = {
            hostname: formData.get('smtpServer'),
            port: formData.get('port'),
            ssl: formData.get('ssl') === 'true',
            username: formData.get('username'),
            password: formData.get('password'),
            displayname: formData.get('displayname'),
        };

        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/smtp`, data);
            toast.success('SMTP settings saved successfully!');
            
        } catch (error) {
            console.error('Error saving SMTP settings:', error);
            const errorMessage = error.response?.data?.message || 'Failed to save settings. Please try again.';
            toast.error(errorMessage);
        }
    };



    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-50 shadow-lg rounded-lg">
            <h2 className="text-2xl font-semibold mb-4">Email Settings</h2>
            <form onSubmit={handleSaveSettings} ref={formRef}> {/* Apply the ref here */}
                <div className="mb-4">
                    <label className="block mb-1 font-medium">
                        SMTP Server:
                    </label>
                    <input
                        type="text"
                        name="smtpServer"
                        defaultValue={smtpSettings.hostname || ''}
                        required
                        className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1 font-medium">
                        Port:
                    </label>
                    <input
                        type="number"
                        name="port"
                        defaultValue={smtpSettings.port || ''}
                        required
                        className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1 font-medium">
                        SSL:
                    </label>
                    <select
                        name="ssl"
                        defaultValue={smtpSettings.ssl !== undefined ? (smtpSettings.ssl ? 'true' : 'false') : 'true'}
                        className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                    </select>
                </div>
                <div className="mb-4">
                    <label className="block mb-1 font-medium">
                        Username:
                    </label>
                    <input
                        type="text"
                        name="username"
                        defaultValue={smtpSettings.username || ''}
                        required
                        className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1 font-medium">
                        Password:
                    </label>
                    <input
                        type="password"
                        name="password"
                        defaultValue={smtpSettings.password || ''}
                        required
                        className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="mb-4">
                    <label className="block mb-1 font-medium">
                        Dsiplay Name:
                    </label>
                    <input
                        type="text"
                        name="displayname"
                        defaultValue={smtpSettings.displayname || ''}
                        required
                        className="w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                </div>
                <div className="flex space-x-2">
                    <button type="submit" className="bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700">
                        Save
                    </button>
                </div>
            </form>
            <ToastContainer />
        </div>
    );
};

export default Email;
