import React, { useEffect, useState } from 'react';
import { getCompanyInformation, createCompanyInformation, updateCompanyInformation } from './Services/settingService';
import { useNavigate } from 'react-router-dom';

const Settingonboarding = ({ onUpdate }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Company_Name: '',
        Logo: '',
        Address: '',
        Contact_Number: '',
        Email: '',
        Website: '',
        About: '',
        createdAt: '',
        updatedAt: ''
    });
    const [file, setFile] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const data = await getCompanyInformation();
           
            if (data.length > 0) {
                setFormData(data[0]);
                onUpdate(data[0]);
            }
        } catch (error) {
            console.error("Failed to fetch company information", error);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        for (const key in formData) {
            if (key !== 'Logo') {
                formDataToSend.append(key, formData[key]);
            }
        }
        if (file) {
            formDataToSend.append('Logo', file);
        }
        try {
            let response;
            if (formData.id) {
                response = await updateCompanyInformation(formData.id, formDataToSend);
            } else {
                response = await createCompanyInformation(formDataToSend);
            }
            setFormData(response);
            navigate('/employees');
            onUpdate(response);
            alert("Company Information Updated Successfully");
            setFile(null);
        } catch (error) {
            console.error("Error submitting form", error);
        }
    };

    const logoUrl = formData.Logo ? `${process.env.REACT_APP_API_URL}/${formData.Logo}` : '';

    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-50 shadow-lg rounded-lg">
            <form onSubmit={handleSubmit} className="space-y-6">
                <label className="flex items-center mb-4">
                    {logoUrl && (
                        <div className="mr-4">
                            <img
                                src={logoUrl}
                                alt="Current Logo"
                                className="w-32 h-32 object-cover rounded border-2 border-blue-500 shadow"
                            />
                        </div>
                    )}
                    <div className="flex-1">
                        <span className="block mb-2">Logo:</span>
                        <input
                            type="file"
                            name="Logo"
                            onChange={handleFileChange}
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            accept="image/*"
                        />
                    </div>
                </label>
                <label>
                    Company Name:
                    <input
                        type="text"
                        name="Company_Name"
                        value={formData.Company_Name}
                        onChange={handleChange}
                        placeholder="Company Name"
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </label>
                <label>
                    Address:
                    <input
                        type="text"
                        name="Address"
                        value={formData.Address}
                        onChange={handleChange}
                        placeholder="Address"
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </label>
                <label>
                    Contact Number:
                    <input
                        type="text"
                        name="Contact_Number"
                        value={formData.Contact_Number}
                        onChange={handleChange}
                        placeholder="Contact Number"
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </label>
                <label>
                    Email:
                    <input
                        type="email"
                        name="Email"
                        value={formData.Email}
                        onChange={handleChange}
                        placeholder="Email"
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </label>
                <label>
                    Website:
                    <input
                        type="text"
                        name="Website"
                        value={formData.Website}
                        onChange={handleChange}
                        placeholder="Website"
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        required
                    />
                </label>
                <label>
                    About:
                    <textarea
                        name="About"
                        value={formData.About}
                        onChange={handleChange}
                        placeholder="About"
                        className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32 resize-none"
                    />
                </label>
                <div className="flex space-x-2">
                    <button type="submit" className="flex-2 bg-blue-600 text-white p-2 rounded-md text-sm hover:bg-blue-700 transition duration-200">
                        Save Changes
                    </button>
                    <button
                        type="button"
                        onClick={() => navigate('/login')}
                        className="flex-2 bg-gray-300 text-gray-800 p-2 rounded-md text-sm hover:bg-gray-400 transition duration-200"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Settingonboarding;
