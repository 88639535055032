import React, { useState, useEffect } from "react";
import { getAllEmployees, deleteEmployee } from "./Services/EmployeesService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";

const EmployeeList = ({ updateEmp }) => {
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [showInactive, setShowInactive] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();


  const updateEmploye = (emp_code) => {

    // Navigate to update employee
    navigate('/employees/' + emp_code);
  }

  useEffect(() => {
    // Load data on initial screen
    fetchEmployees();
  }, []);

  
  useEffect(() => {
    fetchEmployees();
  }, [showInactive]);

  const fetchEmployees = async () => {
    try {
      // Get All Data
      const data = await getAllEmployees();

      // Filter Data
      const filterData = data.filter(
        (employee) => showInactive || employee.active
      );

      // Update UI List
      setFilteredEmployees(filterData);

    } catch (error) {
      console.error("Failed to fetch employees", error);
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  const handleDelete = async (emp_code) => {
    if (window.confirm("Are you sure you want to delete this employee?")) {
      try {

        // Delete Employee
        await deleteEmployee(emp_code);

        // Refresh List
        await fetchEmployees();

        toast.success("Employee deleted successfully!");
      } catch (error) {
        toast.error("Failed to delete employee.");
      }
    }
  };



  if (loading) {
    return <div>Loading employees...</div>; // Loading message
  }

  return (
    <div className="container mx-auto">
      <div className="mt-2">
        <h2 className="text-2xl font-bold mt-8">Employee List</h2>

        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              id="showInactive"
              checked={showInactive}
              onChange={() => { setShowInactive(!showInactive); }}
              className="mr-2"
            />
            <label htmlFor="showInactive" className="mr-4">
              Show Inactive Employees
            </label>
          </div>
          <button
            onClick={() => navigate('/employees/new')}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            New Employee
          </button>
        </div>

        <table className="w-full border-collapse border border-gray-200 mt-4">
          <thead>
            <tr>
              <th className="border px-4 py-2">S.No</th>
              <th className="border px-4 py-2">Employee Code</th>
              <th className="border px-4 py-2">Name</th>
              <th className="border px-4 py-2">Designation</th>
              <th className="border px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee, i) => (
                <tr key={employee.emp_code} className={!employee.active ? "bg-pink-100" : ""}>
                  <td className="border px-4 py-2">{i + 1}</td>
                  <td className="border px-4 py-2">{employee.emp_code}</td>
                  <td className="border px-4 py-2">{employee.name}</td>
                  <td className="border px-4 py-2">{employee.designation}</td>
                  <td className="border px-4 py-2">
                    <button onClick={() => updateEmploye(employee.Id)} className="bg-blue-500 text-white px-2 py-1 rounded mr-2">Edit</button>
                    <button onClick={() => handleDelete(employee.Id)} className="bg-red-500 text-white px-2 py-1 rounded">Delete</button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="border px-4 py-2 text-center">No employees found.</td>
              </tr>
            )}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EmployeeList;