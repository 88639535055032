
import api from './apiService';

export const downloadPayrollPDF = async (emp_id) => {
    try {
        const response = await api.get(`/payroll/pdf/${emp_id}`, {
            responseType: 'blob', // Important for binary data
        });
        return response; // This will be a Blob
    } catch (error) {
        console.error('Error fetching PDF:', error);
        throw error;
    }
};
export const downloadDisbursmentLetter = async (month, year) => {
    try {
        const response = await api.get(`/payroll/disbursmentLetter/${year}/${month}`, {
            responseType: 'blob', // Important for binary data
        });
        return response; // This will be a Blob
    } catch (error) {
        console.error('Error fetching PDF:', error);
        throw error;
    }
};