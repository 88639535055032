import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import {
  createEmployee,
  getEmployeeByID,
  udpateEmployee,
} from "./Services/EmployeesService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

const formatDateForInput = (date) => {
  if (!date) return "";
  const d = new Date(date);
  return d.toISOString().split("T")[0];
};

const calculateSalary = (data) => {
  const {
    basic_salary,
    special_Allowance,
    bonus,
    reimbursement,
    variable_Pay,
    TDS,
    PF,
    esic,
    professional_Tax,
    loan,
    advance,
  } = data

  const gross_Salary =
    parseFloat(basic_salary) +
    parseFloat(special_Allowance || 0) +
    parseFloat(bonus || 0) +
    parseFloat(reimbursement || 0) +
    parseFloat(variable_Pay || 0) +
    parseFloat(advance || 0)

  const deductions =
    parseFloat(TDS || 0) +
    parseFloat(PF || 0) +
    parseFloat(esic || 0) +
    parseFloat(professional_Tax || 0) +
    parseFloat(loan || 0)

  const net_Salary = gross_Salary - deductions

  return {
    ...data,
    gross_Salary,
    net_Salary,
  }
}


const EmployeeAddEdit = () => {
  // red id from url
  const { Id } = useParams();
  const [formData, setFormData] = useState({
    emp_code: "", name: "", father_name: "", address: "", contact_no: "", email: "", dob: "",
    date_of_joining: "", date_Of_Relieving: "", reason_Of_Relieving: "", epf_No: "",
    esic_No: "", pan_No: "", bank_name: "", account_no: "", ifsc_code: "",
    bank_branch: "", designation: "", department: "", active: true,
    basic_salary: "", special_Allowance: "", bonus: "", reimbursement: "",
    variable_Pay: "", TDS: "", PF: "", esic: "", professional_Tax: "",
    loan: "", advance: "", gross_Salary: "", net_Salary: "",
  });

  const [validationErrors, setValidationErrors] = useState({});
  const [activeTab, setActiveTab] = useState("details");
  // const [showForm, setShowForm] = useState(false)


  const navigate = useNavigate();

  useEffect(() => {
    if (Id) {
      const fetchEmployee = async () => {
        try {
          const data = await getEmployeeByID(Id);

          if (data) {
            setFormData({
              ...data,
              ...data?.salaryDetails,

              dob: formatDateForInput(data.dob),
              date_of_joining: formatDateForInput(data.date_of_joining),
              date_Of_Relieving: formatDateForInput(data.date_Of_Relieving),
            });
          }
        } catch (error) {
          console.error("Failed to fetch employee data", error);
        }
      };
      fetchEmployee();
    }
  }, [Id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target

    setFormData((prevData) => {
      const updatedData = {
        ...prevData,
        [name]: type === "checkbox" ? checked : value,
      }

      return calculateSalary(updatedData)
    })
  }


  const validateForm = () => {
    const errors = {};

    if (!formData.emp_code) errors.emp_code = "Employee code required. ";
    if (!formData.name) errors.name = "Name is required.";
    if (!formData.father_name) errors.father_name = "Father's name is required.";
    if (!formData.address) errors.address = "Address is required.";
    if (!formData.contact_no || formData.contact_no.length !== 10) {
      errors.contact_no = "Contact number is required and must be exactly 10 digits.";
    }
    if (!formData.dob) errors.dob = "Date of birth is required.";
    if (!formData.email) errors.email = "Email is required.";
    if (!formData.date_of_joining) errors.date_of_joining = "Date of joining is required.";

    if (!formData.bank_name) errors.bank_name = "Bank name is required. ";
    if (!formData.account_no) errors.account_no = "Account No is required. ";
    if (!formData.ifsc_code) errors.ifsc_code = "Ifsc code is required. ";
    if (!formData.bank_branch) errors.bank_branch = "Bank branch is required. ";
    if (!formData.designation) errors.designation = "Designation is required. ";
    if (!formData.department) errors.department = "Department is required. ";
    if (!formData.basic_salary) errors.basic_salary = "Basic salary is required. ";



    return errors;
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    } else {
      setValidationErrors({}); // Clear previous errors
    }

    if (formData?.date_Of_Relieving) {
      setFormData((prevData) => ({
        ...prevData,
        active: true,
      }));
    }

    try {
      if (formData.Id) {
        await udpateEmployee(formData.Id, formData);
      } else {
        await createEmployee(formData);
      }

      toast.success("Employee data saved successfully!");
      navigate("/employees");
      // setShowForm(false);
    } catch (error) {
      if (error.response && error.response.data.errors) {
        const errors = error.response.data.errors.reduce((acc, err) => {
          acc[err.param] = err.msg; // Create an object with field names and messages
          return acc;
        }, {});
        setValidationErrors(errors);
      } else {
        toast.error("Failed to save employee data.");
      }
    }
  };

  const handleCancel = () => {
    setFormData({
      emp_code: "", name: "", father_name: "",
      address: "", contact_no: "", email: "", dob: "", date_of_joining: "", epf_No: "", esic_No: "", pan_No: "", bank_name: "", account_no: "", ifsc_code: "",
      bank_branch: "", designation: "", department: "", basic_salary: "", special_Allowance: "", bonus: "", reimbursement: "", variable_Pay: "", TDS: "",
      PF: "", esic: "", professional_Tax: "", loan: "", gross_Salary: "", net_Salary: "", date_Of_Relieving: "", reason_Of_Relieving: "", active: true,
    })

    // setShowForm(false)
    navigate("/employees");
  }


  return (
    <div className="max-w-6xl mx-auto p-6 font-sans">
      <div className="flex flex-col items-center justify-center">
        <h2 className="text-2xl font-bold mb-4">
          {formData.Id ? "Edit Employee" : "New Employee"}
        </h2>
        <form onSubmit={handleSubmit} className="w-full">
          <Tabs selectedIndex={activeTab === "details" ? 0 : 1} onSelect={(index) => setActiveTab(index === 0 ? "details" : "salary")}>
            <TabList className="flex space-x-4 mb-4">
              <Tab className="p-2" selectedClassName="border-b-2 border-blue-500">Details</Tab>
              <Tab className="p-2" selectedClassName="border-b-2 border-blue-500">Salary</Tab>
            </TabList>

            <TabPanel>
              <div className="grid grid-cols-2 gap-4 w-full">
                {/* Employee Details Fields */}
                <label>Employee Code:
                  <input type="text" name="emp_code" placeholder="Employee Code" value={formData.emp_code} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.emp_code && <span className="text-red-500">{validationErrors.emp_code}</span>}
                </label>
                <label>Name:
                  <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.name && <span className="text-red-500">{validationErrors.name}</span>}
                </label>
                <label>Father's Name:
                  <input type="text" name="father_name" placeholder="Father's Name" value={formData.father_name} onChange={handleChange} className="border p-2 w-full" />
                </label>
                <label>Address:
                  <input type="text" name="address" placeholder="Address" value={formData.address} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.address && <span className="text-red-500">{validationErrors.address}</span>}
                </label>
                <label>Contact No:
                  <input type="text" name="contact_no" placeholder="Contact No" value={formData.contact_no} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.contact_no && <span className="text-red-500">{validationErrors.contact_no}</span>}
                </label>
                <label>Email:
                  <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.email && <span className="text-red-500">{validationErrors.email}</span>}
                </label>
                <label>Date of Birth:
                  <input type="date" name="dob" value={formData.dob} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.dob && <span className="text-red-500">{validationErrors.dob}</span>}
                </label>
                <label>Date of Joining:
                  <input type="date" name="date_of_joining" value={formData.date_of_joining} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.date_of_joining && <span className="text-red-500">{validationErrors.date_of_joining}</span>}
                </label>
                {formData.Id && (
                  <>
                    <label>Date of Relieving:
                      <input type="date" name="date_Of_Relieving" value={formData.date_Of_Relieving} onChange={handleChange} className="border p-2 w-full" />
                    </label>
                    <label>Reason of Relieving:
                      <input type="text" name="reason_Of_Relieving" placeholder="Reason of Relieving" value={formData.reason_Of_Relieving} onChange={handleChange} className="border p-2 w-full" />
                    </label>
                  </>
                )}
                <label>EPF No:
                  <input type="text" name="epf_No" placeholder="EPF No" value={formData.epf_No} onChange={handleChange} className="border p-2 w-full" />
                </label>
                <label>ESIC No:
                  <input type="text" name="esic_No" placeholder="ESIC No" value={formData.esic_No} onChange={handleChange} className="border p-2 w-full" />
                </label>
                <label>PAN:
                  <input type="text" name="pan_No" placeholder="PAN" value={formData.pan_No} onChange={handleChange} className="border p-2 w-full" />
                </label>
                <label>Bank Name:
                  <input type="text" name="bank_name" placeholder="Bank Name" value={formData.bank_name} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.bank_name && <span className="text-red-500">{validationErrors.bank_name}</span>}
                </label>
                <label>Account No:
                  <input type="text" name="account_no" placeholder="Account No" value={formData.account_no} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.account_no && <span className="text-red-500">{validationErrors.account_no}</span>}
                </label>
                <label>IFSC Code:
                  <input type="text" name="ifsc_code" placeholder="IFSC Code" value={formData.ifsc_code} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.ifsc_code && <span className="text-red-500">{validationErrors.ifsc_code}</span>}
                </label>
                <label>Bank Branch:
                  <input type="text" name="bank_branch" placeholder="Bank Branch" value={formData.bank_branch} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.bank_branch && <span className="text-red-500">{validationErrors.bank_branch}</span>}
                </label>
                <label>Designation:
                  <input type="text" name="designation" placeholder="Designation" value={formData.designation} onChange={handleChange} className="border p-2 w-full" />
                  {validationErrors.designation && <span className="text-red-500">{validationErrors.designation}</span>}
                </label>
                <label>Department:
                    <select name="department" value={formData.department} onChange={handleChange} className="border p-2 w-full">
                      <option value="">Select Department</option>
                      <option value="HR">HR</option>
                      <option value="Engineering">Engineering</option>
                      <option value="Marketing">Marketing</option>
                      <option value="Finance">Finance</option>
                      <option value="Sales">Sales</option>
                    </select>
                    {validationErrors.department && <span className="text-red-500">{validationErrors.department}</span>}
                  </label>

                  <label class="flex items-center space-x-2">
                  <input type="checkbox" name="active" checked={formData.active} onChange={handleChange} className="w-6 h-6 text-blue-600 form-checkbox rounded"/>
                  Active
                </label>
                <label class="flex items-center space-x-2">
                  <input type="checkbox" name="samebank" checked={formData.samebank} onChange={handleChange} className="w-6 h-6 text-blue-600 form-checkbox rounded"/>
                  Account in Same Bank
                </label>
              </div>
            </TabPanel>

            <TabPanel className="w-full">
                {/* Employee Salary */}
                <div className="grid grid-cols-2 gap-4 w-full">
                  <div className="space-y-4">
                    <label>
                      Basic Salary:
                      <input
                        type="number"
                        name="basic_salary"
                        placeholder="Basic Salary"
                        value={formData.basic_salary || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                      {validationErrors.basic_salary && (
                        <span className="text-red-500 mb-2 block">{validationErrors.basic_salary}</span>
                      )}
                    </label>
                    <label>
                      Special Allowance:
                      <input
                        type="number"
                        name="special_Allowance"
                        placeholder="Special Allowance"
                        value={formData.special_Allowance || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                    </label>
                    <label>
                      Bonus:
                      <input
                        type="number"
                        name="bonus"
                        placeholder="Bonus"
                        value={formData.bonus || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                    </label>
                    <label>
                      Advance:
                      <input
                        type="number"
                        name="advance"
                        placeholder="Advance"
                        value={formData.advance || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                    </label>
                    <label>
                      Reimbursement:
                      <input
                        type="number"
                        name="reimbursement"
                        placeholder="Reimbursement"
                        value={formData.reimbursement || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                    </label>
                    <label>
                      Variable Pay:
                      <input
                        type="number"
                        name="variable_Pay"
                        placeholder="Variable Pay"
                        value={formData.variable_Pay || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                    </label>
                  </div>
                  <div className="space-y-4">
                    <label>
                      TDS:
                      <input
                        type="number"
                        name="TDS"
                        placeholder="TDS"
                        value={formData.TDS || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                    </label>
                    <label>
                      PF:
                      <input
                        type="number"
                        name="PF"
                        placeholder="PF"
                        value={formData.PF || ''}
                        onChange={handleChange}
                        className="border p-2 w-full bg-gray-100"
                      />
                    </label>
                    <label>
                      ESIC:
                      <input
                        type="number"
                        name="esic"
                        placeholder="ESIC"
                        value={formData.esic || ''}
                        onChange={handleChange}
                        className="border p-2 w-full bg-gray-100"
                      />
                    </label>
                    <label>
                      Professional Tax:
                      <input
                        type="number"
                        name="professional_Tax"
                        placeholder="Professional Tax"
                        value={formData.professional_Tax || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                    </label>
                    <label>
                      Loan:
                      <input
                        type="number"
                        name="loan"
                        placeholder="Loan"
                        value={formData.loan || ''}
                        onChange={handleChange}
                        className="border p-2 w-full"
                      />
                    </label>
                  </div>
                  <label>
                    Gross Salary:
                    <input
                      type="number"
                      name="gross_Salary"
                      placeholder="Gross Salary"
                      value={formData.gross_Salary || ''}
                      disabled
                      className="border p-2 w-full bg-gray-100"
                    />
                  </label>
                  <label>
                    Net Salary:
                    <input
                      type="number"
                      name="net_Salary"
                      placeholder="Net Salary"
                      value={formData.net_Salary || ''}
                      disabled
                      className="border p-2 w-full bg-gray-100"
                    />
                  </label>
                </div>
              </TabPanel>

          </Tabs>

            <div className="flex mt-4 w-full">
              <div className="mt-auto flex gap-2">
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={handleCancel}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EmployeeAddEdit;