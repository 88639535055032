
import api from './apiService';

export const getTemplate = async (code) => {
    try {
        const response = await api.get(`/templates/${code}`);
        return response;

    } catch (error) {
        console.error("Error fetching temail emplate:", error.response ? error.response.data : error.message);
        throw error;
    }
};

export const updateTemplate = async (code, content, name) => {
    try {
        const response = await api.post(`/templates/${code}`, { content, name });
        return response;
    } catch (error) {
        console.error("Error updating email template:", error.response ? error.response.data : error.message);
        throw error;
    }
};

