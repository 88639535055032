import React, { useState } from 'react';
import { getTemplate, updateTemplate } from './Services/templatesService';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Template = () => {
  const [selectedTemplateType, setSelectedTemplateType] = useState('');
  const [editorContent, setEditorContent] = useState('');
  const [allowSave, setAllowSave] = useState(false);


  const setTypeSelection = (e) => {
    setSelectedTemplateType(e.target.value);
    getTemplateFromCode(e.target.value);
  }


  const getTemplateFromCode = async (code) => {
    try {

      if (!code) {
        return;
      }

      setAllowSave(true);
      const result = await getTemplate(code);
      if (result)
        setEditorContent(result.Content);
      else
        setEditorContent("");
    } catch (ex) {
      toast.error("Error fetching template: " + ex.message);
    }
  }


  const handleSave = async () => {
    try {
      await updateTemplate(selectedTemplateType, editorContent, selectedTemplateType);
      toast.success("Template updated successfully");
    } catch (ex) {
      toast.error("Error updating template: " + ex.message);
    }
  }



  return (
    <div className="min-h-screen p-6 bg-gray-50">
      <div className="max-w-4xl mx-auto p-10 border rounded-lg bg-white shadow-md">
        <div className="space-y-6">
          {/* Template Type Dropdown */}
          <div>
            <label className="block text-sm font-medium text-gray-600">Select Template:</label>
            <select
              onChange={setTypeSelection}
              value={selectedTemplateType}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="">Select a Template</option>
              <option value="salarySlip">Salary Slip</option>
              <option value="salarySlipPDF">Salary Slip PDF</option>
              <option value="disbursementLetter">Disbursement Letter</option>
            </select>
          </div>

          {/* Text Editor for Template Content */}
          {selectedTemplateType && (
            <div>
              <label className="block text-sm font-medium text-gray-600">Edit Template Content (HTML):</label>
              <textarea
                style={{ height: '400px' }}
                value={editorContent}
                onChange={(e) => setEditorContent(e.target.value)}
                className="mt-1 block w-full h-50 overflow-auto border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500"
              />
            </div>
          )}
        </div>

        {/* Save and Cancel Buttons */}
        {selectedTemplateType && (
          <div className="flex justify-start space-x-4 mt-14">
            <button onClick={handleSave} disabled={!allowSave} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
              Save
            </button>
          </div>
        )}

        {/* Preview Section */}
        {selectedTemplateType && (
          <div className="mt-6 max-w-4xl mx-auto p-4 border rounded-md bg-white shadow-sm">
            <h3 className="text-lg font-semibold mb-2">Template Preview:</h3>
            <div dangerouslySetInnerHTML={{ __html: editorContent }} />
          </div>
        )}
      </div>
      <ToastContainer />

    </div>
  );
};

export default Template;
