import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaEdit, FaTrash, FaKey } from 'react-icons/fa';
import authService from './Services/authService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AddUser = () => {
    const [users, setUsers] = useState([]);
    const [formData, setFormData] = useState({ name: '', email: '', password: '', role: 'Admin' });
    const [isEditing, setIsEditing] = useState(false);
    const [editingUserId, setEditingUserId] = useState(null);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [isPasswordChangeVisible, setIsPasswordChangeVisible] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    const API_URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const fetchedUsers = await authService.fetchUsers();
            setUsers(fetchedUsers);
        } catch (error) {
            console.error("Error fetching users", error);
        }
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (isEditing) {
                await axios.put(`${API_URL}/auth/users/${editingUserId}`, formData);
            } else {
                await axios.post(`${API_URL}/auth/register`, formData);
            }
            fetchUsers();
            resetForm();
        } catch (error) {
            console.error("Error submitting form", error);
        }
    };

    const resetForm = () => {
        setFormData({ name: '', email: '', password: '', role: 'Users' });
        setIsEditing(false);
        setEditingUserId(null);
        setIsFormVisible(false);
        setIsPasswordChangeVisible(false);
        setNewPassword('');
    };

    const handleEdit = (user) => {
        setFormData({ name: user.name, email: user.email, password: '', role: user.role });
        setIsEditing(true);
        setEditingUserId(user.id);
        setIsFormVisible(true);
    };

    const handleDelete = async (userId) => {
        try {
            await axios.delete(`${API_URL}/auth/users/${userId}`);
            fetchUsers();
        } catch (error) {
            console.error("Error deleting user", error);
        }
    };

    const handleChangePassword = (userId) => {
        setEditingUserId(userId);
        setIsPasswordChangeVisible(true);
    };

    const handlePasswordChangeSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.put(`${API_URL}/auth/users/${editingUserId}/password`, { password: newPassword });
            toast.success(response.data.message); // 'Password updated successfully'
            resetForm();
        } catch (error) {
            console.error("Error changing password", error);
            toast.error('Error changing password');
        }
    };

    const toggleFormVisibility = () => {
        resetForm();
        setIsFormVisible(!isFormVisible);
    };

    return (
        <div className="max-w-6xl mx-auto p-6 bg-gray-50 shadow-lg rounded-lg">
            {!isFormVisible && !isPasswordChangeVisible && (
                <button 
                onClick={toggleFormVisibility}  
                    className="bg-green-600 text-white p-2 rounded-md hover:bg-green-700 mb-4"
                >
                    New User
                </button>
            )}

            {isFormVisible && (
                <form onSubmit={handleSubmit} className="space-y-4 mb-6">
                    <h2 className="text-xl font-bold mb-4">{isEditing ? 'Edit User' : 'New User'}</h2>
                    <label>
                        Username:
                        <input
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </label>
                    {!isEditing && (
                        <label>
                            Password:
                            <input
                                type="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                                className="w-full p-2 border border-gray-300 rounded-md"
                                required
                            />
                        </label>
                    )}
                    <label>
                        Role:
                        <select
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            className="w-full p-2 border border-gray-300 rounded-md"
                        >
                            <option value="Admin">Admin</option>
                            <option value="Users">Users</option> {/* Update to "Users" */}
                        </select>
                    </label>
                    <div className="flex space-x-2">
                        <button type="submit" className="bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700">
                            {isEditing ? 'Save' : 'Save'}
                        </button>
                        <button 
                            type="button" 
                            onClick={resetForm} 
                            className="bg-gray-300 text-gray-800 p-2 rounded-md hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            )}

            {isPasswordChangeVisible && (
                <form onSubmit={handlePasswordChangeSubmit} className="space-y-4 mb-6">
                    <h2 className="text-xl font-bold mb-4">Change Password</h2>
                    <label>
                        Enter New Password:
                        <input
                            type="password"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full p-2 border border-gray-300 rounded-md"
                            required
                        />
                    </label>
                    <div className="flex space-x-2">
                        <button type="submit" className="bg-blue-600 text-white p-2 rounded-md hover:bg-blue-700">
                            Save 
                        </button>
                        <button 
                            type="button" 
                            onClick={resetForm} 
                            className="bg-gray-300 text-gray-800 p-2 rounded-md hover:bg-gray-400"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            )}

            {!isFormVisible && !isPasswordChangeVisible && (
                <>
                    <h3 className="text-lg font-bold mb-2">User List</h3>
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">Sr. No</th>
                                <th className="py-2 px-4 border-b">Username</th>
                                <th className="py-2 px-4 border-b">Role</th>
                                <th className="py-2 px-4 border-b">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user, index) => (
                                <tr key={user.id} className="border-b">
                                    <td className="py-2 px-4">{index + 1}</td>
                                    <td className="py-2 px-4">{user.name}</td>
                                    <td className="py-2 px-4">{user.role === 'Admin' ? 'Admin' : 'Users'}</td> {/* Updated */}
                                    <td className="py-2 px-4">
                                        <button onClick={() => handleEdit(user)} className="text-blue-500 hover:underline">
                                            <FaEdit /> 
                                        </button>
                                        <button onClick={() => handleDelete(user.id)} className="text-red-500 hover:underline ml-2">
                                            <FaTrash /> 
                                        </button>
                                        <button onClick={() => handleChangePassword(user.id)} className="text-green-500 hover:underline ml-2">
                                            <FaKey /> 
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )}
        <ToastContainer />
        </div>
    );
};

export default AddUser;
