import { useEffect, useState } from "react"

const BankCopy = ({ payroll, samebank, title }) => {

    const [sameBank, setSameBank] = useState([]);

    useEffect(() => {
        if (payroll) {
            const sameBankData = payroll.filter(item => item.Employee.samebank === samebank);
            setSameBank(sameBankData);
        }
    }, [payroll])

    const formatDecimal = (num) => {
        return num !== undefined && num !== null ? num.toFixed(2) : '0.00';
    };

    return (<>
        <table className='salary-sheet-table'>
            <thead>
                <tr style={{ backgroundColor: '#eaeaf1' }}>
                    <th colSpan={samebank ? 4 : 6}>
                        {title}
                    </th>
                </tr>
            </thead>
            <thead>
                <tr style={{ backgroundColor: '#eaeaf1' }}>
                    <th>SNO</th>
                    <th style={{ width: 150 }}>Employee Name</th>
                    <th style={{ width: 150 }}>Account No</th>
                    <th style={{ width: 100, textAlign: 'right' }}>Amount</th>
                    {!samebank && <th style={{ width: 150 }}>Bank Name</th>}
                    {!samebank && <th style={{ width: 150 }}>IFSC Code</th>}
                </tr>
            </thead>
            <tbody>
                {sameBank.map((item, index) => (
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Employee.name}</td>
                        <td>{item.Employee.account_no}</td>
                        <td style={{ textAlign: 'right' }}>{formatDecimal(item.net_Salary)}</td>
                        {!samebank && <td style={{ width: 150 }}>{item.Employee.bank_name}</td>}
                        {!samebank && <td style={{ width: 150 }}>{item.Employee.ifsc_code}</td>}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                <tr style={{ backgroundColor: '#eaeaf1' }}>
                    <td colSpan={3}><b>Total:</b></td>
                    <td style={{ textAlign: 'right' }}>
                        <b>{formatDecimal(sameBank.reduce((acc, item) => acc + parseFloat(item.net_Salary || 0), 0))}</b>
                    </td>
                    {!samebank && <td colSpan={2}></td>}
                </tr>
            </tfoot>
        </table>
    </>

    )
}

export default BankCopy;