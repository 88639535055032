import api from './apiService';
import { toast } from 'react-toastify';


// Create a new employee with salary
export const createEmployee = async (employeeData) => {
    try {
        const response = await api.post('/employees', employeeData);
        return response;
    } catch (error) {
        console.error('Error creating employee with salary:', error);
        throw error;
    }
};

// Get all employees with their salaries
export const getAllEmployees = async () => {
    const token = localStorage.getItem('accessToken');
    if (!token) {
    toast.error("No token found. Please log in.");
        return;
    }

    try {
        const response = await api.get('/employees');
        return response;
    } catch (error) {
        console.error('Error fetching all employees with salary:', error);
        throw error;
    }
};

// Get an employee with their salary by ID
export const getEmployeeByID = async (Id) => {
    try {
        const response = await api.get(`/employees/${Id}`);
        return response;
    } catch (error) {
        console.error(`Error fetching employee with salary by ID ${Id}:`, error);
        throw error;
    }
};

// Update an existing employee with salary
export const udpateEmployee = async (Id, employeeData) => {
    try {
        const response = await api.put(`/employees/${Id}`, employeeData);
        return response;
    } catch (error) {
        console.error('Error updating employee with salary:', error);
        throw error;
    }
};

// Delete an employee with salary
export const deleteEmployee = async (Id) => {
    try {
        await api.delete(`/employees/${Id}`);
    } catch (error) {
        console.error('Error deleting employee with salary:', error);
        throw error;
    }
};


