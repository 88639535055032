// src/components/App.js
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import Header from "./components/Body/Header.js";
import Sidebar from "./components/Body/Sidebar.js";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";

import AttendanceForm from "./components/AttendanceForm";
import PayrollList from "./components/payrollList";
import AuthProvider from "./components/context/authContext";
import Tabs from "./components/settingTabs.js";
import { getCompanyInformation } from './components/Services/settingService.js';
import Settingonboarding from "./components/settingonboarding.js";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Drawer } from '@mui/material';
import EmployeeAddEdit from "./components/EmployeeAddEdit.js";
import EmployeeList from "./components/EmployeeList.js";

const App = () => {
  const [companyInfo, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false); // State for Drawer visibility

  const handleUpdate = (info) => {
    setCompanyInfo(info);
  };

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <AuthProvider>
      <Router>
        <ToastContainer />
        <MainContent
          companyInfo={companyInfo}
          loading={loading}
          setLoading={setLoading}
          setCompanyInfo={setCompanyInfo}
          handleUpdate={handleUpdate}
          toggleDrawer={toggleDrawer} // Pass toggle function
          drawerOpen={drawerOpen} // Pass drawer state
        />
      </Router>
    </AuthProvider>
  );
};

const MainContent = ({ companyInfo, loading, setLoading, setCompanyInfo, handleUpdate, toggleDrawer, drawerOpen }) => {
  const location = useLocation();
  const isAuthPage = location.pathname === "/login" || location.pathname === "/settingonboarding" || location.pathname === "/" ;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        let data = await getCompanyInformation();
        if (data.length > 0) {
          data[0].Logo = process.env.REACT_APP_API_URL + "/settings/logo";
          setCompanyInfo(data[0]);
        }
      } catch (error) {
        toast.error("Error fetching company information", error);
      } finally {
        setLoading(false);
      }
    };
    if (!isAuthPage)
      fetchData();
  }, [location.pathname, setLoading, setCompanyInfo, isAuthPage]);

  return (
    <div className="flex h-screen overflow-hidden">
      {/* MUI Drawer for mobile */}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
      >
        {/* Render Sidebar only if not on auth pages */}
        {!isAuthPage && <Sidebar companyInfo={companyInfo} loading={loading} />}
      </Drawer>

      {/* Sidebar for Desktop */}
      <div className="hidden md:block">
        {!isAuthPage && <Sidebar companyInfo={companyInfo} loading={loading} />}
      </div>

      <div className="flex flex-col w-full h-screen overflow-auto">
        {!isAuthPage && <Header toggleDrawer={toggleDrawer} />}
        <div className={`p-1 ${!isAuthPage ? 'mt-4' : ''} overflow-auto`}>
          <Routes>
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/login" element={<Login />} />
            <Route path="/settingonboarding" element={<Settingonboarding />} />
            <Route
              path="/employees"
              element={
                <ProtectedRoute>
                  <EmployeeList />
                </ProtectedRoute>
              }
            />
            <Route
              path="/employees/new"
              element={
                <ProtectedRoute>
                  <EmployeeAddEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/employees/:Id"
              element={
                <ProtectedRoute>
                  <EmployeeAddEdit />
                </ProtectedRoute>
              }
            />
            <Route
              path="/AttendanceForm"
              element={
                <ProtectedRoute>
                  <AttendanceForm />
                </ProtectedRoute>
              }
            />
            <Route
              path="/payroll"
              element={
                <ProtectedRoute>
                  <PayrollList />
                </ProtectedRoute>
              }
            />
            <Route path="/company-info" element={<Tabs onUpdate={handleUpdate} />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
