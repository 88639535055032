import api from './apiService';

export const fetchEmployees = async () => {
    const response = await api.get(`/attendance/employees`);
  
    return response;
};

export const fetchAttendanceData = async (empCode, date) => {
    const response = await api.get(`/attendance/${empCode}/${date}`);
    return response.data;
};

export const submitAttendanceData = async (empCode, date, data) => {
    if (empCode && date) {
        return await api.put(`/attendance/${empCode}/${date}`, data);
    } else {
        return await api.post(`/attendance`, data);
    }
};
