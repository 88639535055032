// src/components/Sidebar.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faMoneyBill, faChartBar, faCog } from '@fortawesome/free-solid-svg-icons';

const Sidebar = ({ companyInfo }) => {
    var isAdmin = false;

    const user = localStorage.getItem("user");
    if (user) {
        const userData = JSON.parse(user);
        isAdmin = userData.role === 'Admin';
    }
    const menuitems = [
        { href: "/AttendanceForm", icon: faMoneyBill, label: "Attendance" },
        { href: "/employees", icon: faChartBar, label: "Employees" },
        { href: "/payroll", icon: faUserCheck, label: "Salary Sheet" }
    ];

    if (isAdmin)
        menuitems.push({ href: "/company-info", icon: faCog, label: "Settings" });

    return (
        <div className="h-screen w-64 bg-[#003366] shadow-md transition duration-300 md:block">
            <div className="p-5 text-center text-white border-b border-blue-800 mb-5">
                <div className="profile-section">
                    {companyInfo ? (
                        <>
                            <img src={companyInfo.Logo} alt="Company Logo" className="w-20 h-20 rounded-full mx-auto mb-2" />
                            <h3 className="text-lg font-semibold">{companyInfo.Company_Name}</h3>
                        </>
                    ) : (
                        <p>No company information available.</p>
                    )}
                </div>
            </div>
            <nav className="nav-section">
                <ul className="p-0 m-0 list-none">
                    {menuitems.map(({ href, icon, label }) => (
                        <li key={label}>
                            <a
                                href={href}
                                className="flex items-center p-3 text-gray-200 hover:text-yellow-400 border-b border-blue-800 transition-colors duration-300"
                            >
                                <FontAwesomeIcon icon={icon} className="mr-4" />
                                <span>{label}</span>
                            </a>
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
