import React, { useState, useContext } from "react";
import { TextField, Button, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import authService from "./Services/authService";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Login = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({ name: "", password: "" });
  const { login, setAuthToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await authService.login(formData);
      login(response); // Store the user data in context
      setAuthToken(response.accessToken); // Store the access token

      // Redirect based on whether company info exists or not
      if (response.hasCompanyInfo) {
        navigate("/employees"); 
      } else {
        navigate("/settingonboarding"); 
      }
    } catch (error) {
      toast.error("Error logging in: " + (error.response ? error.response.data.message : error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-screen">
      {/* Blue Background */}
      <div className="hidden md:flex md:w-1/2 bg-blue-500 items-center justify-center text-white flex-col p-10">
        <h1 className="text-4xl font-semibold mb-4">Welcome!</h1>
        <p className="text-2xl text-center mb-4">Log in to access your account!</p>
      </div>

      {/* Login Form */}
      <div className="w-full md:w-1/2 p-8 flex items-center justify-center bg-white rounded-lg shadow-lg transform transition-transform hover:scale-105">
        <form onSubmit={handleSubmit} className="space-y-4 w-full max-w-sm">
          <h1 className="text-2xl font-semibold text-center mb-4">Login To Your Account</h1>
          <TextField
            fullWidth
            variant="outlined"
            label="User Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            type="text"
            required
            className="transition-shadow hover:shadow-lg"
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            type="password"
            required
            className="transition-shadow hover:shadow-lg"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className="transform transition-transform hover:scale-105"
          >
            {loading ? <CircularProgress size={24} /> : "Log In"}
          </Button>
        </form>
      </div>
      <ToastContainer />

    </div>
  );
};

export default Login;
