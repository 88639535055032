import axios from 'axios';
import { toast } from 'react-toastify';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

api.interceptors.response.use(
    (response) => response.data,
    
    (error) => {
        if (error.response) {
            console.error('Error:', error.response.data);
           
            if (error.response.status === 401) {
                toast.error("Unauthorized! Please log in again.");
            } else {
                toast.error(`Error: ${error.response.data.message || 'Something went wrong!'}`);
            }
        } else {
            console.error('Error:', error.message);
            toast.error(`Error: ${error.message}`);
        }
        return Promise.reject(error);
    }
);
export default api;
