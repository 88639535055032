import React, { useEffect, useState } from 'react';
import {
    getCompanyInformation,
    createCompanyInformation,
    updateCompanyInformation
} from './Services/settingService';
import { FaMapMarkerAlt, FaPhone, FaGlobe, FaEnvelope, FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Setting = ({ onUpdate }) => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        Company_Name: '',
        Logo: '',
        Address: '',
        Contact_Number: '',
        Email: '',
        Website: '',
        About: '',
        createdAt: '',
        updatedAt: ''
    });


    const [isEditing, setIsEditing] = useState(false);
    const [file, setFile] = useState(null);
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const fetchData = async () => {
        try {
            let data = await getCompanyInformation();
            if (data.length > 0) {
                data[0].Logo = process.env.REACT_APP_API_URL + "/settings/logo";
                data = data[0]
                setFormData(data);
                onUpdate(data);
            }
        } catch (error) {
            console.error("Failed to fetch company information", error);
        }
    };
    const handleCancel = () => {
        navigate('/employeewithssalary');
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        const formDataToSend = new FormData();
        for (const key in formData) {
            if (key !== 'Logo') {
                formDataToSend.append(key, formData[key]);
            }
        }
        if (file) {
            formDataToSend.append('Logo', file);
        }
        try {
            let response;
            if (isEditing && formData.Id) {
                response = await updateCompanyInformation(formData.Id, formDataToSend);
            } else {
                response = await createCompanyInformation(formDataToSend);
            }
            if (response) {

                let url = process.env.REACT_APP_API_URL
                url = url.replace("/api", "")
                response.Logo = url + "/" + response.Logo
                setFormData(response);
                onUpdate(response);
                setIsEditing(false);
                toast.success("Company Information Updated Successfully");
                setFile(null);
            }
        } catch (error) {
            console.error("Error submitting form", error);
        }
    };
    return (
        <div className="max-w-4xl mx-auto p-6 bg-gray-50 shadow-lg rounded-lg">
            {isEditing ? (
                <form onSubmit={handleSubmit} className="space-y-6">
                    <label className="flex items-center mb-4">

                        {formData.Logo && (
                            <div className="mr-4">
                                <img
                                    src={formData.Logo}
                                    alt="Current Logo"
                                    className="w-32 h-32 object-cover rounded border-2 border-blue-500 shadow"
                                />
                            </div>
                        )}
                        <div className="flex-1">
                            <span className="block mb-2">Logo:</span>
                            <input
                                type="file"
                                name="Logo"
                                onChange={handleFileChange}
                                className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                accept="image/*"
                            />
                        </div>
                    </label>
                    <label>
                        Company Name:
                        <input
                            type="text"
                            name="Company_Name"
                            value={formData.Company_Name}
                            onChange={handleChange}
                            placeholder="Company Name"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </label>
                    <label>
                        Address:
                        <input
                            type="text"
                            name="Address"
                            value={formData.Address}
                            onChange={handleChange}
                            placeholder="Address"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </label>
                    <label>
                        Contact Number:
                        <input
                            type="text"
                            name="Contact_Number"
                            value={formData.Contact_Number}
                            onChange={handleChange}
                            placeholder="Contact Number"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            placeholder="Email"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </label>
                    <label>
                        Website:
                        <input
                            type="text"
                            name="Website"
                            value={formData.Website}
                            onChange={handleChange}
                            placeholder="Website"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            required
                        />
                    </label>
                    <label>
                        About:
                        <textarea
                            name="About"
                            value={formData.About}
                            onChange={handleChange}
                            placeholder="About"
                            className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 h-32 resize-none"
                        />
                    </label>
                    <div className="flex space-x-2">
                        <button type="submit" className="flex-2 bg-blue-600 text-white p-2 rounded-md text-sm hover:bg-blue-700 transition duration-200">
                            Save Changes
                        </button>
                        <button
                            type="button"
                            onClick={() => setIsEditing(false)}
                            className="flex-2 bg-gray-300 text-gray-800 p-2 rounded-md text-sm hover:bg-gray-400 transition duration-200"
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            ) : (
                <div className="space-y-4">
                    <div className="flex justify-center mb-4">
                        <img src={formData.Logo} alt="Company Logo" className="w-32 h-32 object-cover rounded-full border-2 border-blue-500 shadow" />
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center mb-2">
                            <FaInfoCircle className="text-blue-500 mr-2" />
                            <span className="font-bold text-gray-700">Company Name:</span>
                        </div>
                        <p className="text-gray-600">{formData.Company_Name}</p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center mb-2">
                            <FaMapMarkerAlt className="text-blue-500 mr-2" />
                            <span className="font-bold text-gray-700">Address:</span>
                        </div>
                        <p className="text-gray-600">{formData.Address}</p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center mb-2">
                            <FaPhone className="text-blue-500 mr-2" />
                            <span className="font-bold text-gray-700">Contact Number:</span>
                        </div>
                        <p className="text-gray-600">{formData.Contact_Number}</p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center mb-2">
                            <FaGlobe className="text-blue-500 mr-2" />
                            <span className="font-bold text-gray-700">Website:</span>
                        </div>
                        <p className="text-gray-600">{formData.Website}</p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center mb-2">
                            <FaEnvelope className="text-blue-500 mr-2" />
                            <span className="font-bold text-gray-700">Email:</span>
                        </div>
                        <p className="text-gray-600">{formData.Email}</p>
                    </div>
                    <div className="bg-white shadow-md rounded-lg p-4">
                        <div className="flex items-center mb-2">
                            <FaInfoCircle className="text-blue-500 mr-2" />
                            <span className="font-bold text-gray-700">About:</span>
                        </div>
                        <p className="text-gray-600">{formData.About}</p>
                    </div>
                    <div className="flex space-x-2">
                        <button
                            onClick={() => setIsEditing(true)}
                            className="flex-2 bg-blue-600 text-white p-2 rounded-md text-sm hover:bg-blue-700 transition duration-200"
                        >
                            Edit
                        </button>
                        <button
                            type="button"
                            onClick={handleCancel}
                            className="flex-2 bg-gray-500 text-white p-2 rounded-md text-sm hover:bg-gray-600 transition duration-200"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            )}
            <ToastContainer />

        </div>
    );
};
export default Setting;





















