//////////////////////////payrolllist
import React, { useState, useEffect } from 'react';
import { fetchPayrolls, savePayroll, getSalarySheetExcel } from './Services/payrollService';
import { sendPayrollPDFMail } from '../components/Services/sendMailService';
import { downloadDisbursmentLetter, downloadPayrollPDF } from './Services/pdfService';
//import { disbursementTemplates } from './Template.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';
import BankCopy from './BankCopy';

const formatDecimal = (num) => {
  return num !== undefined && num !== null ? num.toFixed(2) : '0.00';
};

const PayrollList = () => {
  const [month, setMonth] = useState((new Date().getMonth() + 1).toString().padStart(2, '0'));
  const [year, setYear] = useState(new Date().getFullYear());
  const [payrolls, setPayrolls] = useState([]);
  const [error, setError] = useState('');
  const [showDownload, setShowDownload] = useState(false);
  const [editPopupOpen, setEditPopupOpen] = useState(false);
  const [currentPayroll, setCurrentPayroll] = useState(null);

  const formatDateToIST = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-IN', {
      timeZone: 'Asia/Kolkata',
      dateStyle: 'medium',
      timeStyle: 'short',
    });
  };

  // State variables for payroll fields
  const [basicSalary, setBasicSalary] = useState(0);
  const [PL, setPL] = useState(0);
  const [SL, setSL] = useState(0);
  const [LWP, setLWP] = useState(0);
  const [specialAllowance, setSpecialAllowance] = useState(0);
  const [bonus, setBonus] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [reimbursement, setReimbursement] = useState(0);
  const [variablePay, setVariablePay] = useState(0);
  const [TDS, setTDS] = useState(0);
  const [PF, setPF] = useState(0);
  const [ESIC, setESIC] = useState(0);
  const [professionalTax, setProfessionalTax] = useState(0);
  const [loan, setLoan] = useState(0);
  const [netSalary, setNetSalary] = useState(0);
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmailFor, setSendingEmailFor] = useState(null);      ////track state for email
  const [downloadingPDFFor, setDownloadingPDFFor] = useState(null);   ////track state for pdf
  const [selectedmonth, setSelectedMonth] = useState('');

  // Load Current
  useEffect(() => {
    handleFetchPayrolls();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const handleFetchPayrolls = async () => {
    if (month && year) {
      try {
        const data = await fetchPayrolls(year, month);
        setPayrolls(data);
        setShowDownload(data.length > 0);
        setError('');
        setSelectedMonth(new Date(0, month - 1).toLocaleString('default', { month: 'long' }));
      } catch (error) {
        setPayrolls([]);
        setShowDownload(false);
        setError(error.message);
      }
    }
  };



  const downloadExcel = async () => {
    try {
      // Get the HTML table element
      const table = document.getElementById('salary-table');

      // Create a new workbook
      const wb = XLSX.utils.table_to_book(table, { sheet: 'Sheet1' });

      // Generate and download the Excel file
      XLSX.writeFile(wb, 'Salary Sheet.xlsx');
    }
    catch (error) {
      console.error('Error downloading Excel:', error);
      toast.error('Error downloading Excel:' + error.message);
    }
  };

  const downloadBankCopy = async () => {
    try {
      // Get the HTML table element
      const same_bank_table = document.getElementById('same-bank-table');
      const other_bank_table = document.getElementById('other-bank-table');

      // Create a new workbook
      const sheet1 = XLSX.utils.table_to_sheet(same_bank_table, { sheet: 'HDFC' });
      const sheet2 = XLSX.utils.table_to_sheet(other_bank_table, { sheet: 'Non-HDFC' });

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, sheet1, 'HDFC');
      XLSX.utils.book_append_sheet(wb, sheet2, 'Non-HDFC');


      // Generate and download the Excel file
      XLSX.writeFile(wb, 'Salary Sheet Bank Copy.xlsx');


    }
    catch (error) {
      console.error('Error downloading Excel:', error);
      toast.error('Error downloading Excel:' + error.message);
    }
  };

  const downloadPDF = async (payroll) => {
    const monthValue = +month.padStart(2, '0'); // Ensure month is two digits
    setDownloadingPDFFor(payroll.empId);
    try {
      const blob = await downloadPayrollPDF(`${payroll.empId}/${year}/${monthValue}`);
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');


    } catch (error) {
      console.error('Error downloading PDF:', error);
    } finally {
      setDownloadingPDFFor(null);
    }
  };

  const downloadLetter = async () => {
    const monthValue = +month.padStart(2, '0'); // Ensure month is two digits
    try {
      const blob = await downloadDisbursmentLetter(month, year);
      const url = window.URL.createObjectURL(blob);

      window.open(url, '_blank');
    } catch (error) {
      console.error('Error Downloading Disbursment Letter:', error);
    }
  };

  const handleSaveEdit = async () => {
    try {
      const updatedData = {
        basic_salary: basicSalary,
        PL,
        SL,
        LWP,
        special_Allowance: specialAllowance,
        bonus,
        advance,
        reimbursement,
        variable_Pay: variablePay,
        TDS,
        PF,
        esic: ESIC,
        professional_Tax: professionalTax,
        loan,
        net_Salary: netSalary,
        email_Sent: emailSent,
        present_Days:presentDays
      };

      // Ensure you're passing the current year and month when saving
      await savePayroll(currentPayroll.emp_code, year, month, updatedData);
      setEditPopupOpen(false);
      await handleFetchPayrolls();
    } catch (error) {
      console.error('Error saving payroll:', error);
      setError('Failed to save payroll. Please try again.');
    }
  };

  const [workingDays, setWorkingDays] = useState(0);
  const [presentDays, setPresentDays] = useState(0);

  useEffect(() => {
    if (workingDays === 0) {
      setNetSalary(0);
      return;
    }

    // Construct salary object
    const salary = {
      basic_salary: basicSalary,
      special_Allowance: specialAllowance,
      bonus: bonus,
      reimbursement: reimbursement,
      variable_Pay: variablePay,
      advance: advance,
      TDS: TDS,
      PF: PF,
      esic: ESIC,
      professional_Tax: professionalTax,
      loan: loan,
    };

    // Calculate gross salary
    const grossSalary = parseFloat(salary.basic_salary || 0) +
      parseFloat(salary.special_Allowance || 0) +
      parseFloat(salary.bonus || 0) +
      parseFloat(salary.reimbursement || 0) +
      parseFloat(salary.variable_Pay || 0) +
      parseFloat(salary.advance || 0);

    // Calculate total deductions
    const totalDeductions = parseFloat(salary.TDS || 0) +
      parseFloat(salary.PF || 0) +
      parseFloat(salary.esic || 0) +
      parseFloat(salary.professional_Tax || 0) +
      parseFloat(salary.loan || 0);

    // Calculate daily salary based on gross salary
    const dailySalary = parseFloat(salary.basic_salary) / workingDays;

    // Calculate net salary
    let net_Salary = grossSalary - totalDeductions;

    // Adjust for loss of pay (LWP) if applicable
    const absentDays = workingDays - presentDays;
    const result = net_Salary - (dailySalary * LWP);


    // Update state with calculated net salary
    setNetSalary(result);
  }, [basicSalary, specialAllowance, bonus, reimbursement, advance, variablePay, TDS, PF, ESIC, professionalTax, loan, workingDays, presentDays, PL, SL, LWP]);


  useEffect(() => {
    if (workingDays > 0) {
      setPresentDays(workingDays - (PL + SL + LWP));
    } else {
      setPresentDays(0); // Set to 0 if working days are not valid
    }
  }, [workingDays, PL, SL, LWP]);


  const handleEditEmployee = (payroll) => {
    setCurrentPayroll(payroll);
    setBasicSalary(payroll.basic_salary || 0);
    setPL(payroll.PL || 0);
    setSL(payroll.SL || 0);
    setLWP(payroll.LWP || 0);
    setWorkingDays(payroll.working_Days || 0); // Set working days
    setPresentDays(payroll.present_Days); // Set present days
    setSpecialAllowance(payroll.special_Allowance || 0);
    setBonus(payroll.bonus || 0);
    setAdvance(payroll.advance || 0);
    setReimbursement(payroll.reimbursement || 0);
    setVariablePay(payroll.variable_Pay || 0);
    setTDS(payroll.TDS || 0);
    setPF(payroll.PF || 0);
    setESIC(payroll.esic || 0);
    setProfessionalTax(payroll.professional_Tax || 0);
    setLoan(payroll.loan || 0);

    // setEmailSent(payroll.email_Sent || false);
    setEditPopupOpen(true);
  };


  const handleSendEmail = async (payroll) => {


    try {
      setSendingEmailFor(payroll.empId);
      const response = await sendPayrollPDFMail(payroll.empId, year, month);

      // Refresh List
      handleFetchPayrolls();

      toast.success(response.message || 'Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      toast.error('Failed to send email. Please try again.');
    } finally {
      setSendingEmailFor(null);
    }
  };


  return (
    <div className="p-4 sm:p-6">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">Salary Sheet</h1>
      <div className="flex flex-col sm:flex-row">
        <select
          className="border border-gray-300 p-2 mb-2 sm:mr-2 rounded w-full sm:w-auto h-10"
          value={month}
          onChange={(e) => setMonth(e.target.value)}
        >
          <option value="">Select Month</option>
          {Array.from({ length: 12 }, (_, i) => (
            <option key={i} value={(i + 1).toString().padStart(2, '0')}>
              {new Date(0, i).toLocaleString('default', { month: 'long' })}
            </option>
          ))}
        </select>
        <input
          type="text"
          placeholder="Enter Year"
          className="w-full sm:w-32 px-2 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:mb-0 mb-2 h-10"
          value={year}
          onChange={(e) => {
            const value = e.target.value;
            // Validate: allow only numbers and ensure it’s a four-digit year
            if (/^\d{0,4}$/.test(value)) {
              setYear(value);
            }
          }}
        />&nbsp;



        <button onClick={handleFetchPayrolls} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 text-sm mr-2">
          Generate
        </button>&nbsp;

        {showDownload && <button onClick={downloadExcel} className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 mr-2 w-full sm:w-auto">
          Download Salary Sheet (Excel)
        </button>}
        {showDownload && <button onClick={downloadBankCopy} className="px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600 mr-2 w-full sm:w-auto">
          Download Bank Copy (Excel)
        </button>}
        {showDownload && <button onClick={() => downloadLetter(payrolls)} className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 w-full sm:w-auto">
          Download Disbursment Letter (PDF)
        </button>}


      </div>


      {/* {error && <div className="text-red-600 mt-4">{error}</div>} */}
      {!showDownload && <div><h2>Payroll not found.</h2></div>}
      {payrolls.length > 0 && (
        <>
          <div style={{ overflowY: 'auto', overflowX: 'scroll' }}>
            <table className='salary-sheet-table' id='salary-table'>
              <thead className='sticky-row'>
                <tr>
                  <th style={{ fontSize: 20, backgroundColor: '#eaeaf1' }} colSpan={26}>Salary Sheet for {selectedmonth}-{year}</th>
                </tr>
                <tr style={{ backgroundColor: '#eaeaf1' }}>
                  <th colSpan={4}></th>
                  <th colSpan={5}>Leave</th>
                  <th colSpan={7}>Earnings</th>
                  <th colSpan={5}>Deductions</th>
                  <th></th>
                  <th colSpan={2}>Employer</th>
                  <th colSpan={2}>Actions</th>
                </tr>
                <tr style={{ backgroundColor: '#eaeaf1' }}>
                  <th>#</th>
                  <th>Code</th>
                  <th style={{ minWidth: 200 }} className="sticky-column">Name</th>
                  <th>Basic Salary</th>
                  <th>Working Days</th>
                  <th>PL</th>
                  <th>SL</th>
                  <th>LWP</th>
                  <th>Present Days</th>
                  <th>Basic</th>
                  <th>Sp. Allow.</th>
                  <th>Bonus</th>
                  <th>Advance</th>
                  <th>Reim.</th>
                  <th>Variable Pay</th>
                  <th>Gross Salary</th>
                  <th>TDS</th>
                  <th>PF</th>
                  <th>ESIC</th>
                  <th>Prof. Tax</th>
                  <th>Loan</th>
                  <th>Net Salary</th>
                  <th>PF</th>
                  <th>ESIC</th>
                  <th>Download</th>
                  <th style={{ minWidth: 150 }}>Email</th>
                </tr>
              </thead>
              <tbody>
                {payrolls.map((payroll, index) => {
                  const calculatedPresentDays = payroll.present_Days;// payroll.working_Days - (payroll.PL + payroll.SL + payroll.LWP);
                  const gross_salary = payroll.basic_salary + payroll.special_Allowance + payroll.bonus + payroll.reimbursement + payroll.variable_Pay + payroll.advance;
                  payroll.gross_salary = gross_salary;
                  return (
                    <tr key={payroll.emp_code}>
                      <td>{index + 1}</td>
                      <td>{payroll.emp_code}</td>
                      <td  class="sticky-column">
                        <button
                          onClick={() => handleEditEmployee(payroll)}
                          className="text-blue-500 hover:underline"
                        >
                          <b> {payroll.Employee ? payroll.Employee.name : 'N/A'}</b>
                        </button>
                      </td>
                      <td className='amount'>{formatDecimal(payroll.Employee.salaryDetails.basic_salary)}</td>
                      <td>{payroll.working_Days}</td>
                      <td>{formatDecimal(payroll.PL)}</td>
                      <td>{formatDecimal(payroll.SL)}</td>
                      <td>{formatDecimal(payroll.LWP)}</td>
                      <td>{calculatedPresentDays}</td>
                      <td className='amount'>{formatDecimal(payroll.basic_salary)}</td>
                      <td className='amount'>{formatDecimal(payroll.special_Allowance)}</td>
                      <td className='amount'>{formatDecimal(payroll.bonus)}</td>
                      <td className='amount'>{formatDecimal(payroll.advance)}</td>
                      <td className='amount'>{formatDecimal(payroll.reimbursement)}</td>
                      <td className='amount'>{formatDecimal(payroll.variable_Pay)}</td>
                      <td className='amount'>{formatDecimal(gross_salary)}</td>
                      <td className='amount'>{formatDecimal(payroll.TDS)}</td>
                      <td className='amount'>{formatDecimal(payroll.PF)}</td>
                      <td className='amount'>{formatDecimal(payroll.esic)}</td>
                      <td className='amount'>{formatDecimal(payroll.professional_Tax)}</td>
                      <td className='amount'>{formatDecimal(payroll.loan)}</td>
                      <td className='amount'>{formatDecimal(payroll.net_Salary)}</td>
                      <td className='amount'>{formatDecimal(payroll.E_PF)}</td>
                      <td className='amount'>{formatDecimal(payroll.e_esic)}</td>
                      <td>
                        <a href="#"
                          onClick={() => downloadPDF(payroll)}
                          disabled={downloadingPDFFor === payroll.empId}
                        >
                          {downloadingPDFFor === payroll.empId ? 'Downloading...' : 'PDF'}
                        </a>
                      </td>
                      <td>
                        {payroll.emailSentAt && (
                          <div style={{ fontSize: '10px' }}>{formatDateToIST(payroll.emailSentAt)}</div>
                        )}
                        <a href="#"
                          onClick={() => handleSendEmail(payroll)}
                          disabled={sendingEmailFor === payroll.empId}
                        >
                          {sendingEmailFor === payroll.empId ? 'Sending...' : 'Send'}
                        </a>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
              <tfoot>
                <tr style={{ backgroundColor: '#eaeaf1' }}>
                  <td colSpan={3}><b>Total:</b></td>
                  <td className='amount'>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.Employee.salaryDetails.basic_salary || 0), 0))}</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.basic_salary || 0), 0))}</b>
                  </td>
                  <td>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.special_Allowance || 0), 0))}</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className='amount'>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.gross_salary || 0), 0))}</b>
                  </td>
                  <td className='amount'>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.TDS || 0), 0))}</b>
                  </td>
                  <td className='amount'>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.PF || 0), 0))}</b>
                  </td>
                  <td className='amount'>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.esic || 0), 0))}</b>
                  </td>
                  <td></td>
                  <td></td>
                  <td>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.net_Salary || 0), 0))}</b>
                  </td>
                  <td>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.E_PF || 0), 0))}</b>
                  </td>
                  <td>
                    <b>{formatDecimal(payrolls.reduce((acc, payroll) => acc + parseFloat(payroll.e_esic || 0), 0))}</b>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="flex flex-col lg:flex-row">
            <div class="w-full lg:w-1/2 p-4">
              <label>Same Bank</label>
              <div id='same-bank-table'>
                <BankCopy payroll={payrolls} samebank={true} title={`Salary for ${selectedmonth}-${year}`} />
              </div>
            </div>

            <div class="w-full lg:w-1/2 p-4">
              <label>Other Bank</label>
              <div id='other-bank-table'>
                <BankCopy payroll={payrolls} samebank={false} title={`Salary for ${selectedmonth}-${year}`} />
              </div>
            </div>
          </div>
        </>
      )}

      {/* Edit Payroll Popup */}
      {editPopupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-5 flex justify-center items-center mt-14">
          <div className="bg-white p-6 rounded-lg w-full  overflow-y-auto max-w-[80vh] max-h-[90vh]">
            <h2 className="text-xl mb-4">Edit Payroll</h2>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label>Basic Salary</label>
                <input type="number" value={basicSalary} onChange={(e) => {
                  setBasicSalary(Number(e.target.value));
                }} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Working Days</label>
                <input type="number" value={workingDays} onChange={(e) => setWorkingDays(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              {/* Other fields remain unchanged */}
              <div>
                <label>PL</label>
                <input type="number" value={PL} onChange={(e) => setPL(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>SL</label>
                <input type="number" value={SL} onChange={(e) => setSL(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>LWP</label>
                <input type="number" value={LWP} onChange={(e) => setLWP(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Present Days</label>
                <input type="number" value={presentDays} onChange={(e) => setPresentDays(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Special Allowance</label>
                <input type="number" value={specialAllowance} onChange={(e) => setSpecialAllowance(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Bonus</label>
                <input type="number" value={bonus} onChange={(e) => setBonus(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Advance</label>
                <input type="number" value={advance} onChange={(e) => setAdvance(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Reimbursement</label>
                <input type="number" value={reimbursement} onChange={(e) => setReimbursement(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Variable Pay</label>
                <input type="number" value={variablePay} onChange={(e) => setVariablePay(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>TDS</label>
                <input type="number" value={TDS} onChange={(e) => setTDS(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>PF</label>
                <input type="number" value={PF} onChange={(e) => setPF(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>ESIC</label>
                <input type="number" value={ESIC} onChange={(e) => setESIC(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Professional Tax</label>
                <input type="number" value={professionalTax} onChange={(e) => setProfessionalTax(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Loan</label>
                <input type="number" value={loan} onChange={(e) => setLoan(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div>
                <label>Net Salary</label>
                <input type="number" value={netSalary.toFixed(2)} onChange={(e) => setNetSalary(Number(e.target.value))} className="border rounded p-2 w-full" />
              </div>
              <div className="col-span-2">
                <label>Email Sent</label>
                <input type="checkbox" checked={emailSent} onChange={(e) => setEmailSent(e.target.checked)} />
              </div>
            </div>
            <div className="mt-4">
              <button onClick={handleSaveEdit} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
                Save
              </button>
              <button onClick={() => setEditPopupOpen(false)} className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 ml-2">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}



    </div>


  );
};

export default PayrollList;
