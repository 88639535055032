import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import 'react-tabs/style/react-tabs.css'; // Optional for default styling
import Setting from './Setting';
import AddUser from './AddUser';
import Email from './Email';

import Template from './Template';

const CustomTabs = ({ onUpdate }) => {
    return (
        <Tabs className="cursor-pointer">
            {/* Tab navigation */}
            <TabList className="flex space-x-4 mb-4">
                <Tab className="p-2 focus:outline-none" selectedClassName="border-b-2 border-blue-500">
                    Settings
                </Tab>
                <Tab className="p-2 focus:outline-none" selectedClassName="border-b-2 border-blue-500">
                    User
                </Tab>
                <Tab className="p-2 focus:outline-none" selectedClassName="border-b-2 border-blue-500">
                    Email
                </Tab>
                <Tab className="p-2 focus:outline-none" selectedClassName="border-b-2 border-blue-500">
                    Template
                </Tab>
            </TabList>

            {/* Tab panels */}
            <TabPanel>
                <Setting onUpdate={onUpdate} />
            </TabPanel>
            <TabPanel>
                <AddUser />
            </TabPanel>
            <TabPanel>
                <Email />
            </TabPanel>
            <TabPanel>
                <Template /> 
            </TabPanel>
        </Tabs>
    );
};

export default CustomTabs;
