// src/components/Header.js
import React, { useContext, useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faBell, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';

const Header = ({ toggleDrawer }) => {
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleLogout = (e) => {
        e.preventDefault();
        logout();
        navigate('/login');
    };

    const toggleDropdown = () => {
        setDropdownOpen(prev => !prev);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className="sticky top-0 min-h-16 flex justify-between items-center w-full bg-[#003366] text-white shadow-md border-b border-blue-800 px-5 z-10">
            <div className="flex items-center">
                <button onClick={toggleDrawer} className="md:hidden p-3 text-gray-200 focus:outline-none">
                    <FontAwesomeIcon icon={faBars} />
                </button>
                <h1 className="text-2xl text-gray-200">Payroll Management</h1>
            </div>
            <div className="flex items-center">
                <div className="relative flex items-center">
                    <AccountCircle
                        fontSize="large"
                        className="text-gray-200 mr-5 cursor-pointer hover:text-yellow-400"
                        onClick={toggleDropdown}
                    />
                    {isDropdownOpen && (
                        <div
                            ref={dropdownRef}
                            className="absolute right-0 top-12 bg-[#003366] border border-blue-800 rounded-md shadow-lg"
                        >
                            <p className="text-white font-bold p-2">{user ? user.name : "User"}</p>
                            <a
                                href="#"
                                onClick={handleLogout}
                                className="block text-yellow-400 text-sm p-2 transition-colors duration-300 hover:text-white hover:underline"
                            >
                                Logout
                            </a>
                        </div>
                    )}
                </div>
            </div>
        </header>
    );
};

export default Header;
