import React, { createContext, useState, useEffect } from "react";
import axios from 'axios';

// Create Auth Context
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [accessToken, setToken] = useState(() => {
    return localStorage.getItem('accessToken') || null;
  });

  // Set axios default headers for authorization
  useEffect(() => {
    if (accessToken) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  }, [accessToken]);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
  };

  const setAuthToken = (authToken) => {
    setToken(authToken);
    localStorage.setItem('accessToken', authToken);
  };

  const logout = () => {
    localStorage.removeItem('accessToken');
    setUser(null);
    setToken(null);
    localStorage.removeItem("user");
  };

  // Directly return the user presence for authentication check
  const isAuthenticated = () => !!user;

  return (
    <AuthContext.Provider value={{ user, login, logout, setAuthToken, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
